import React, { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { getMediaUrl } from "@utils/navigation";
import { getBooleanCookie, setBooleanCookie } from "@utils/simpleCookies";
import { Anecdote, AudioPlayerEnabled } from "@utils/data";
import { AudioPlayerProps } from "./index.interface";
import { getBoutonsPlayer } from "./svgsBoutonsPlayer";

/* eslint "jsx-a11y/media-has-caption": "off" */
declare global {
  interface Window {
    Smarquee: any;
  }
}

const AudioListPlayer = ({ anecdote, enableAnecdote }: AudioPlayerProps) => {
  const [currentTrack, setTrackIndex] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(false);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [isListMinimized, setIsListMinimized] = React.useState(true);
  const [isMuted, setIsMuted] = React.useState(false);
  const [isAutoplay, setIsAutoplay] = React.useState(true);
  const l = anecdote?.length;

  const refPlayer = useRef<AudioPlayer>();
  const refTitlePlayer = useRef<HTMLHeadingElement>();
  enableAnecdote = l ? enableAnecdote : AudioPlayerEnabled.Disabled;

  useEffect(() => {
    setupMarquee();

    const statePaused = getBooleanCookie("rzp-paused");
    const stateMinimized = getBooleanCookie("rzp-minimized");
    if (stateMinimized) {
      setIsMinimized(stateMinimized);
    }
    const stateMuted = getBooleanCookie("rzp-muted");
    if (stateMuted) {
      setIsMuted(stateMuted);
      // Si le player est mute, on ne veut pas faire jouer l'audio en volume 0 pour rien.
      setIsAutoplay(!stateMuted);
    }
    if (statePaused) {
      setIsAutoplay(false);
    }

    // Accrocher action sur mute button dans le component pour sauvegarder etat mute entre les pages et visite.
    const btnMute = document.querySelector("button.rhap_volume-button");
    if (btnMute) btnMute.addEventListener("click", handleClickMute);

    return () => {
      if (btnMute) btnMute.removeEventListener("click", handleClickMute);
    };
  }, [currentTrack]);

  const setupMarquee = () => {
    if (refTitlePlayer.current) {
      setTimeout(() => {
        const SmarqueeClass = window.Smarquee;
        if (SmarqueeClass) {
          const curMarquee = new SmarqueeClass({
            element: refTitlePlayer.current,
            styleOptions: {
              scrollingTitleMargin: 54,
              pausePercent: 0
            }
          });
          curMarquee.init();
        }
      }, 100);
    }
  };

  const { title, src } = anecdote[currentTrack];

  const handleClickNext = () => {
    setTrackIndex((c) => (c < anecdote.length - 1 ? c + 1 : 0));
  };
  const handleClickPrev = () => {
    setTrackIndex((c) => (c > 0 ? c - 1 : anecdote.length - 1));
  };

  const handleEnd = (e) => {
    setTrackIndex((c) => (c < anecdote.length - 1 ? c + 1 : c));
    handleOnPause(e);
  };

  const handleClickMinimize = () => {
    setIsMinimized((m) => {
      const newVal = !m;
      setBooleanCookie("rzp-minimized", newVal, 7);
      return newVal;
    });
  };

  const handleOnPlaying = (e) => {
    setIsPlaying(true);
    setIsPaused(false);
    setBooleanCookie("rzp-paused", false, 7);
  };
  const handleOnPause = (e) => {
    setIsPlaying(false);
    setIsPaused(true);
    setBooleanCookie("rzp-paused", true, 7);
  };

  const handleClickMute = () => {
    setTimeout(() => {
      if (refPlayer.current && refPlayer.current.audio.current) {
        // Ce player n'utiliser pas la prop "muted" mais mets plutôt le volume à 0
        const isPlayerMute = refPlayer.current.audio.current.volume === 0;
        setBooleanCookie("rzp-muted", isPlayerMute, 7);
        setIsMuted(isPlayerMute);
        setIsAutoplay(!isPlayerMute);
        // Redémarrer au arrêter l'audio quand on décide de fermer l'audio
        if (refPlayer.current.audio.current.paused) {
          if (!isPlayerMute) refPlayer.current.audio.current.play();
        } else if (isPlayerMute) {
          refPlayer.current.audio.current.pause();
        }
      }
    }, 1);
  };

  const getClassRZP = () => {
    let classRZP = "rzplayer-container ";
    if (isMinimized) classRZP += "minimized";
    if (isPlaying) classRZP += " isPlaying";
    switch (enableAnecdote) {
      case AudioPlayerEnabled.Enabled:
        classRZP += " player-enabled";
        break;
      case AudioPlayerEnabled.Hidden:
        classRZP += " player-hidden";
        break;
    }
    return classRZP;
  };

  const handleClickBtnToggleList = () => {
    setIsListMinimized((m) => {
      const newVal = !m;
      return newVal;
    });
  };

  const handleClickBtnList = (indexAudio) => {
    setTrackIndex(indexAudio);
  };

  const getNarrButtons = () => {
    return (
      <>
        {anecdote.map((curAnecdote: Anecdote, index: number) => {
          const isCurrent = index === currentTrack;
          return (
            <button
              className={`bnarr ${isCurrent ? "current" : ""}`}
              type="button"
              onClick={() => handleClickBtnList(index)}
              key={`btn-anecdote-${curAnecdote.title}`}
            >
              {curAnecdote.title}
            </button>
          );
        })}
      </>
    );
  };

  const getToggleNarrListText = () => {
    // const nbAnecdotes = anecdote && anecdote.length ? anecdote.length : "";
    if (isListMinimized) {
      // return `Voir la liste (${currentTrack + 1} / ${nbAnecdotes}) ►`;
      return `Voir la liste ►`;
    }
    // return `Cacher la liste (${currentTrack + 1} / ${nbAnecdotes}) ▼`;
    return `Cacher la liste ▼`;
  };

  const getCurrentListPositionText = () => {
    const nbAnecdotes = anecdote && anecdote.length ? anecdote.length : "";
    return `${currentTrack + 1} / ${nbAnecdotes}`;
  };

  if (enableAnecdote === AudioPlayerEnabled.Disabled) {
    const nbAnecdotes = anecdote && anecdote.length ? anecdote.length : "";
    return <></>;
  }

  return (
    <div className={getClassRZP()}>
      <div className="rzplayer-wrap">
        <button type="button" className="rzp-minimize" aria-label="Réduire" onClick={handleClickMinimize}>
          {getBoutonsPlayer()}
        </button>
        <AudioPlayer
          className="rzplayer"
          ref={refPlayer}
          src={getMediaUrl(src)}
          volume={0.7}
          header={<h4 ref={refTitlePlayer}>{title}</h4>}
          autoPlay={isAutoplay && enableAnecdote === AudioPlayerEnabled.Enabled}
          muted={isMuted}
          hasDefaultKeyBindings={false}
          showSkipControls
          loop={false}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrev}
          onPlaying={handleOnPlaying}
          onPause={handleOnPause}
          onEnded={handleEnd}
        />
        <div className={`liste-narr-module ${isListMinimized ? "ismini" : "nomini"}`}>
          {/* Souvenirs de Richard Z. Sirois */}
          <div className="wrap-btn-toggle-liste">
            <button type="button" onClick={handleClickBtnToggleList} className="btn-toggle-liste">
              {getToggleNarrListText()}
            </button>
            <span>{getCurrentListPositionText()}</span>
          </div>
          <div className="liste-narr">
            <p>Les souvenirs de Richard Z. Sirois</p>
            <div className="liste-narr-wrap">{getNarrButtons()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioListPlayer;
