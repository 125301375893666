/** *
 * Tommy Brière
 * Ébauche du composant de gestion de transition
 * N'est pas terminé ni utilisé actuellement
 */

import React, { useState, useEffect, useContext, useCallback } from "react";
import { navigate } from "@reach/router";
import AudioListPlayer from "@components/AudioListPlayer";
import { AudioPlayerEnabled } from "@utils/data";
import LoadingOverlay from "../LoadingOverlay";
import { preload } from "../../utils/navigation";
import { TransitionState } from "./index.interface";
import { NavigationContext } from "./NavigationContext";

export const Loading = () => {
  const { incLoadingCounter, decLoadingCounter } = useContext(NavigationContext);
  useEffect(() => {
    incLoadingCounter();
    return () => {
      decLoadingCounter();
    };
  }, [decLoadingCounter, incLoadingCounter]);

  return <></>;
};

export const TransitionRoot = ({ children, anecdote }) => {
  const [enableAnecdote, setEnableAnecdoteState] = useState(AudioPlayerEnabled.Disabled);
  const [transitionState, setTransitionState] = useState(TransitionState.Idle);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [target, setTarget] = useState<[string, any, any]>(null);
  const [Transition, setTransition] = useState(null);
  const [transParams, setTransParams] = useState({});
  const isLoading = transitionState === TransitionState.Loading || transitionState === TransitionState.InfiniteLoading;
  const disableAnecdote = isLoading && Transition;

  useEffect(() => {
    const doBgPreload = async (path) => {
      incLoadingCounter();
      await preload(path);
      decLoadingCounter();
    };

    switch (transitionState) {
      case TransitionState.Idle:
        if (loadingCounter > 0) {
          setTransitionState(TransitionState.Loading);
        } else if (target) {
          doBgPreload(target[0]);
          setTransitionState(TransitionState.Loading);
        }
        break;
      case TransitionState.Loading:
        if (loadingCounter <= 0) {
          if (target) {
            if (target[1]) {
              navigate(target[0], target[1]);
            } else {
              navigate(target[0]);
            }
            setTarget(null);
            if (target[2]) {
              target[2]();
            }
          } else if (!Transition) {
            setTransitionState(TransitionState.Idle);
          }
        }
        break;
    }
  }, [transitionState, loadingCounter, target, Transition]);

  const goto = useCallback(async (path: string, state?: any, trans?, tParams?): Promise<any> => {
    return new Promise((resolve) => {
      if (target && target[2]) {
        target[2]();
      }
      setTarget([path, state, resolve]);
      setTransition(() => trans);
      setTransParams(tParams);
    });
  }, []);

  const incLoadingCounter = useCallback(() => {
    setLoadingCounter((value) => {
      return value + 1;
    });
  }, []);
  const decLoadingCounter = useCallback(() => {
    // Settimeout prevent flickers
    setTimeout(() => {
      setLoadingCounter((value) => {
        return value - 1;
      });
    }, 100);
  }, []);
  const instantEternalLoading = useCallback(() => {
    setLoadingCounter(10);
    setTransitionState(TransitionState.InfiniteLoading);
    setTransition(LoadingOverlay);
    setTransParams({});
  }, []);
  const transitionComplete = useCallback(() => {
    setTransition(null);
    setTransParams({});
  }, []);

  const setEnableAnecdote = useCallback((enable) => {
    if (enable) {
      setEnableAnecdoteState(enable);
    }
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        goto,
        incLoadingCounter,
        decLoadingCounter,
        transitionState,
        instantEternalLoading,
        transitionComplete,
        setEnableAnecdote
      }}
    >
      {children}
      {isLoading && Transition ? <Transition {...transParams} /> : null}
      <AudioListPlayer
        enableAnecdote={
          disableAnecdote && enableAnecdote === AudioPlayerEnabled.Enabled ? AudioPlayerEnabled.Hidden : enableAnecdote
        }
        anecdote={anecdote}
      />
    </NavigationContext.Provider>
  );
};
