

import React from 'react'
import universal, { setHasBabelPlugin } from 'react-universal-component'

setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
  ignoreBabelRename: true
}

const t_0 = universal(import('__react_static_root__/src/pages/404'), universalOptions)
      t_0.template = '__react_static_root__/src/pages/404'
      
const t_1 = universal(import('__react_static_root__/src/pages/home'), universalOptions)
      t_1.template = '__react_static_root__/src/pages/home'
      
const t_2 = universal(import('__react_static_root__/src/pages/placeList'), universalOptions)
      t_2.template = '__react_static_root__/src/pages/placeList'
      
const t_3 = universal(import('__react_static_root__/src/pages/gallery'), universalOptions)
      t_3.template = '__react_static_root__/src/pages/gallery'
      
const t_4 = universal(import('__react_static_root__/src/pages/settings'), universalOptions)
      t_4.template = '__react_static_root__/src/pages/settings'
      
const t_5 = universal(import('__react_static_root__/src/pages/politiques'), universalOptions)
      t_5.template = '__react_static_root__/src/pages/politiques'
      
const t_6 = universal(import('__react_static_root__/src/pages/credits'), universalOptions)
      t_6.template = '__react_static_root__/src/pages/credits'
      
const t_7 = universal(import('__react_static_root__/src/pages/commentaires'), universalOptions)
      t_7.template = '__react_static_root__/src/pages/commentaires'
      
const t_8 = universal(import('__react_static_root__/src/pages/intro'), universalOptions)
      t_8.template = '__react_static_root__/src/pages/intro'
      
const t_9 = universal(import('__react_static_root__/src/pages/partenaires'), universalOptions)
      t_9.template = '__react_static_root__/src/pages/partenaires'
      
const t_10 = universal(import('__react_static_root__/src/pages/place'), universalOptions)
      t_10.template = '__react_static_root__/src/pages/place'
      
const t_11 = universal(import('__react_static_root__/src/pages/collectable'), universalOptions)
      t_11.template = '__react_static_root__/src/pages/collectable'
      
const t_12 = universal(import('__react_static_root__/src/pages/galleryMedia'), universalOptions)
      t_12.template = '__react_static_root__/src/pages/galleryMedia'
      
const t_13 = universal(import('__react_static_root__/src/pages/plan'), universalOptions)
      t_13.template = '__react_static_root__/src/pages/plan'
      

// Template Map
export default {
  '__react_static_root__/src/pages/404': t_0,
'__react_static_root__/src/pages/home': t_1,
'__react_static_root__/src/pages/placeList': t_2,
'__react_static_root__/src/pages/gallery': t_3,
'__react_static_root__/src/pages/settings': t_4,
'__react_static_root__/src/pages/politiques': t_5,
'__react_static_root__/src/pages/credits': t_6,
'__react_static_root__/src/pages/commentaires': t_7,
'__react_static_root__/src/pages/intro': t_8,
'__react_static_root__/src/pages/partenaires': t_9,
'__react_static_root__/src/pages/place': t_10,
'__react_static_root__/src/pages/collectable': t_11,
'__react_static_root__/src/pages/galleryMedia': t_12,
'__react_static_root__/src/pages/plan': t_13
}
// Not Found Template
export const notFoundTemplate = "__react_static_root__/src/pages/404"

