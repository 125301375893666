import { useCallback, useContext, useEffect, useMemo } from "react";
import { prefetch, useRouteData } from "react-static";
import { useLocation } from "@reach/router";

import { NavigationContext } from "../components/TransitionRoot/NavigationContext";
import { MEDIA_ROOT, USE_OPTMISED_MEDIA, WEBSITE_SITE_ROOT, RELOAD_AFTER_XWGL } from "./config";

import type { LocationType, WithPrevState, Place, CollectableObject, Preset, MediaTransform } from "./data";
import { mediaPresets } from "../data/mediaPresets";

/* eslint react-hooks/rules-of-hooks: "off" */
/* eslint react-hooks/exhaustive-deps: "off" */

const webpSupported = true;

export const trimLastSlash = (path: string) => {
  const len = path.length;
  if (path && path !== "" && path.substring(len - 1) === "/") {
    return path.substring(0, len - 1);
  }
  return path;
};

export const trimFirstSlash = (path: string) => {
  if (path && path !== "" && path.substring(0, 1) === "/") {
    return path.substring(1);
  }
  return path;
};

function addMediaSufix(prefix: string, spec: { width; height; method; type }) {
  const { width, height, method, type } = spec;
  const tini = type === "png";
  return `${prefix}.${method}_${width}_${height}_${tini ? "t" : "s"}.${type}`;
}

const getOptimisedImg = (path: string, presetName?: Preset): string => {
  const transform: MediaTransform = mediaPresets[presetName];

  switch (transform?.type) {
    case "jpeg":
      return addMediaSufix(path, { ...transform, type: webpSupported ? "webp" : "jpeg" });
    case "png":
      return addMediaSufix(path, { ...transform, type: webpSupported ? "webp" : "png" });
  }

  // Not transformed
  return path;
};

export const getMediaUrl = (path: string, presetName?: Preset): string => {
  if (!path) {
    return "";
  }
  if (USE_OPTMISED_MEDIA) {
    path = getOptimisedImg(path, presetName);
  }
  const mediaRoot = MEDIA_ROOT || "https://dev-maisonsirois.storage.googleapis.com";
  return `${trimLastSlash(mediaRoot)}/${trimFirstSlash(path)}`;
};

export interface GetUrlFunction {
  (lang: string, type: LocationType, id?: string, sub?: string | number): string;
}

export const getGetUrl = ({ collectables, places, urls }): GetUrlFunction => {
  const getPlace = (id) => {
    return places.find((p) => {
      return p.id === id;
    });
  };

  return (lang, type: LocationType, id?, sub?) => {
    if (type === "home") {
      return "/";
    }
    if (id) {
      switch (type) {
        case "collectable":
          const collectable: CollectableObject = collectables.find((c) => c.id === id);
          const p3: Place = getPlace(collectable.next.id);
          return `/${p3[lang].url || p3.id}/${collectable[lang].url || collectable.id}`;
        case "place":
          const place: Place = getPlace(id);
          if (typeof sub === "number" || typeof sub === "string") {
            return `/${place[lang].url || place.id}/${sub}`;
          }
          return `/${place[lang].url || place.id}`;
        case "gallery":
          if (typeof sub === "number" || typeof sub === "string") {
            return `/galerie-photo/${id}/${sub}`;
          }
          return `/galerie-photo/${id}`;
        default:
          if (typeof sub === "number" || typeof sub === "string") {
            return `/${type}/${id}/${sub}`;
          }
          return `/${type}/${id}`;
      }
    }
    const loc = urls[lang];
    return `/${loc[type]}`;
  };
};

export const useGetUrl = (): GetUrlFunction => {
  const { collectables, places, cinematics, urls } = useRouteData();
  const fn = getGetUrl({ collectables, places, urls });
  const getUrl = useCallback(fn, [collectables, places, urls]);
  return getUrl;
};

export const useHomeUrl = () => {
  return useUrl({ type: "home" });
};

export const useLang = () => {
  return "fr";
};

export interface UseUrlParams {
  type: LocationType;
  id?: string;
  sub?: string | number;
}

export const preload = (url): Promise<void> => {
  // template preload is broken, so preload only templates
  return prefetch(url, { type: "data" });
};

export const useUrl = ({ type, id, sub }: UseUrlParams) => {
  const get = useGetUrl();
  const lang = useLang();
  const url = get(lang, type, id, sub);
  preload(url);
  return url;
};

/**
 * Obtient des informations sur l'url précédente
 * N'attache pas de gestionnaire de touche
 */
export const usePrevUrl = (def?: UseUrlParams) => {
  const location = useLocation();

  const state: WithPrevState = location?.state;
  const prevState = state?.prevState;
  let p: string;
  try {
    p = useUrl(prevState?.location || def);
  } catch (err) {
    p = useHomeUrl();
  }
  const url = useMemo(() => {
    return {
      path: p,
      state: { ...prevState?.state, usedBack: true }
    };
  }, [p, prevState?.state]);
  return url;
};

/**
 * Retourne les informations nécessaire pour retourner à la page précédente
 * Installe un gestionnaire de touche pour gérer la touche escape
 */
export const usePrevLink = (def?: UseUrlParams) => {
  const url = usePrevUrl(def);
  const { goto } = useContext(NavigationContext);
  useEffect(() => {
    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        goto(url.path, url.state);
      }
    };

    document.addEventListener("keydown", keydown);
    return () => {
      document.removeEventListener("keydown", keydown);
    };
  }, []);
  return url;
};

/**
 * Génération d'un état utilisation pour indiquer comment naviguer à cette page
 */
export const useReturnToSelfState = (): WithPrevState => {
  const location = useLocation();
  const current = useRouteData();
  const { lang, type, id } = current;
  return useMemo(() => {
    const goBackState: WithPrevState = {
      prevState: {
        location: { lang, type, id },
        state: location ? location.state : null
      }
    };
    return goBackState;
  }, [lang, type, id, location?.state]);
};

let recordCallback: ({ type, id }) => void = () => {};
export function setRecordCallBack(callback) {
  recordCallback = callback;
}

export const useRecordCurrentLocation = () => {
  if (typeof document === "undefined") {
    return;
  }
  const current = useRouteData();
  recordCallback(current);
};

export const isBrowser = typeof document !== "undefined";

export const isIOS = isBrowser && /iPad|iPhone|iPod/.test(navigator?.userAgent);
let wglUsage = 0;
/**
 * Détermine si on doit recharger la page
 *
 * @param reloadPriority
 * @returns
 */
export const webGLLeakCrashFix = (reloadPriority: number) => {
  const limit = RELOAD_AFTER_XWGL - reloadPriority * 10;
  console.log("webGLLeakCrashFix", wglUsage, limit);
  if (isIOS || wglUsage >= limit) {
    return true;
  }
  return false;
};

/**
 * Incrémente le compteur de wbgl affiché
 */
export const webGLShown = () => {
  wglUsage++;
};

export const absUrl = (url) => {
  return url.indexOf(WEBSITE_SITE_ROOT) === 0 ? url : `${WEBSITE_SITE_ROOT}${url}`;
};
