import { PlacePosition } from "@components/PersistedData/placesSlice";

export type InteractType = "collectable";
export type LocationType =
  | "place"
  | "settings"
  | "home"
  | "politiques"
  | "credits"
  | "plan"
  | "commentaires"
  | "intro"
  | "gallery"
  | "partenaires"
  | InteractType;

export type LocaleId = "fr";

export interface PrevState {
  location: LocationParts;
  state: WithPrevState;
}

export interface WithPrevState {
  prevState?: PrevState;
}

export interface LocationLink {
  type: LocationType;
  id: string;
}
export interface LocationParts {
  type: LocationType;
  id?: string;
  lang: LocaleId;
  sub?: string;
}

export interface ReferencableLang {
  title?: string;
  description?: string;
  url?: string;

  metaDescription?: string;
}

export interface Anecdote {
  src: string;
  duration?: string;
  title: string;
}

export interface InteractPoint {
  /**
   * Identifiant de l'objet à débloqué (colectable)
   */
  id: string;
  /**
   * Position 3D dans la sphère
   */
  position3D: { x: number; y: number; z: number };
  /**
   * Image à placer
   */
  image?: string;
  /**
   * Texte à mettre sur le point intéractif
   */
  fr?: {
    title: string;
  };
}
export interface Referencable {
  id: string;
  fr: ReferencableLang;
}

export enum CollectableType {
  Page = "page",
  Standard = ""
}

export interface Navigable extends Referencable {
  type?: CollectableType;
  coverImage?: string;
  /**
   * Automatiquement rempli dans le processus de prétraitement
   * À partir des données de places
   */
  next?: {
    id: string;
  };
}

export type LitePlaceLang = ReferencableLang;

export interface LitePlace extends Navigable {
  /**
   * Cinematique à faire jouer avant d'entrer dans le lieux
   */
  cinematic: string;
  cartePosition: { x: number; y: number };
  /**
   * Temps de début et de fin de l'anim de transition de l'accueil
   */
  transition: number[];
  fr: LitePlaceLang;
}

export interface Ambiance {
  src: string;
  position3D: { x: number; y: number; z: number };
  volume?: number;
}
export interface Place extends LitePlace {
  /**
   * default media folder to look for
   */
  mediaFolder: string;
  /**
   * Média 360
   */
  media360: string;
  /**
   * Sons d'ambiancse à faire jouer
   */
  ambiances?: Ambiance[];
  /**
   * Images alterantives à utiliser si le media360 ne pas pas être lancé
   */
  imageSrcs: string[];

  /**
   * Point intéractifs dans le lieux
   */
  interactPoints: InteractPoint[];

  /**
   * Cinematique à faire jouer avant d'entrer dans le lieux
   */
  cinematic: string;
  /**
   * Média de transition
   */
  transitionMedia: string;
  /**
   * Position initiale de la caméra à l'entrée
   */
  cameraPosition: { x: number; y: number };
}

export interface CollectableObject extends Navigable {
  id: string;

  fr: CollectableLang;

  modelSrc: string;
  imageSrc: string;

  modelScale?: number;
  objectPosition?: { x: number; y: number; z: number };

  /**
   * Automatiquement rempli dans le processus de prétraitement
   * À partir des données de places
   */
  next?: {
    id: string;
  };
}

export interface CollectableLang extends ReferencableLang {
  reference?: string;
}

export interface CinematicAltImages {
  text: string;
}

export interface GalleryMedia extends Navigable {
  imageSrc: string;
  width: number;
  height: number;
}

/**
 * Localisation des url du jeux
 */
export type UrlLocalisation = Record<LocationType, string>;

export interface MediaTransform {
  type: "jpeg" | "png" | "gltf";
  width: number;
  height: number;
  method: "scale" | "fit" | "cover" | "thumb";
}

export interface ResizeTransform {
  type: "jpeg" | "png" | "webp" | "replace";
  width: number;
  height: number;
  method: "scale" | "fit" | "cover" | "thumb";
}

export enum AudioPlayerEnabled {
  Disabled = 1,
  Hidden = 2,
  Enabled = 3
}

export type Preset =
  | "cinematicItem"
  | "posterImage"
  | "collectableItem"
  | "collectableAlt"
  | "placeAlt"
  | "360"
  | "model";
