import React, { useEffect } from "react";
import { useRouteData } from "react-static";
import { useI18n } from "../../I18nextProvider";
import "./cookie-consent/consent.css";
import "./cookie-consent/consent.js";

let hidden = false;

const CConsent = () => {
  const i18n = useI18n();
  const { lang } = useRouteData();

  useEffect(() => {
    CookieConsent.init({
      title: i18n.t("cookieConsent.title"),
      text: i18n.t("cookieConsent.text"),
      btnYes: i18n.t("cookieConsent.btnYes"),
      btnNo: i18n.t("cookieConsent.btnNo"),
      btnFermer: i18n.t("global.fermer"),
      hidden,
      website: "maisonsirois"
    });
  }, [i18n, lang]);

  return <></>;
};

export function openCookiePopup(e) {
  if (e) {
    e.preventDefault();
  }
  CookieConsent.show();
}

export function hideCookiePopup() {
  hidden = true;
  if (typeof CookieConsent === "object") {
    CookieConsent.hide();
  }
}

export default CConsent;
