// Fonction pour écrire un cookie
export const setCookie = (name, value, days) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);

  const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieValue;
};

// Fonction pour lire la valeur d'un cookie
export const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(`${name}=`) === 0) {
      return cookie.substring(name.length + 1, cookie.length);
    }
  }

  return null;
};

export const setBooleanCookie = (name: string, value: boolean, days: number) => {
  const stringValue = value ? "true" : "false";
  setCookie(name, stringValue, days);
};

export const getBooleanCookie = (name: string): boolean | null => {
  const stringValue = getCookie(name);
  if (stringValue === "true") {
    return true;
  }
  if (stringValue === "false") {
    return false;
  }
  return null;
};
