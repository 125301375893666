import React, { useContext, useEffect } from "react";
import { useRouteData } from "react-static";

import Seo from "@components/Seo";
import InitInteractivity from "@components/InitInteractivity";
import { PersistedData } from "@components/PersistedData";
import CookieConsent from "@components/CookieConsent";
import { NavigationContext } from "@components/TransitionRoot/NavigationContext";
import { I18nProvider } from "./I18nextProvider";

const WrappedApp = ({ children }) => {
  const { enableAnecdote, item } = useRouteData();
  const { setEnableAnecdote } = useContext(NavigationContext);
  const itemId = item?.id;
  useEffect(() => {
    setEnableAnecdote(enableAnecdote);
  }, [enableAnecdote, setEnableAnecdote, itemId]);

  if (typeof document !== "undefined") {
    return (
      <PersistedData>
        <I18nProvider>
          <InitInteractivity />
          <Seo />
          {children}
          <CookieConsent />
        </I18nProvider>
      </PersistedData>
    );
  }

  return (
    <PersistedData>
      <I18nProvider>
        <Seo />
        {children}
      </I18nProvider>
    </PersistedData>
  );
};

export default WrappedApp;
