import { createContext } from "react";
import { AudioPlayerEnabled } from "@utils/data";
import { TransitionState } from "./index.interface";

function e() {
  throw new Error("doit être overridé, assurez-vous d'avoir placé correctement le composant NavigationRoot");
}

export const NavigationContext = createContext({
  transitionState: TransitionState.Idle,
  goto: (path: string, state?: any, trans?, transParams?): Promise<any> => {
    e();
    return Promise.resolve();
  },
  incLoadingCounter: (): void => {
    e();
  },
  decLoadingCounter: (): void => {
    e();
  },
  instantEternalLoading: (): void => {
    e();
  },
  transitionComplete: (): void => {
    e();
  },
  setEnableAnecdote: (a: AudioPlayerEnabled): void => {}
});
