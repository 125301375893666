import React from "react";

// Fonction pour écrire un cookie
export const getBoutonsPlayer = () => {
  return (
    <>
      <svg
        id="btn-player-playing"
        xmlns="http://www.w3.org/2000/svg"
        width="31"
        height="28.786"
        viewBox="0 0 31 28.786"
      >
        <g transform="translate(-5 -6.357)">
          <path
            id="Tracé_3269"
            data-name="Tracé 3269"
            d="M7,6.357a2,2,0,0,0-2,2V26.5a2,2,0,0,0,2,2H21.976v6.643L27.6,28.5H34a2,2,0,0,0,2-2V8.357a2,2,0,0,0-2-2Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <g id="Groupe_3886" data-name="Groupe 3886" transform="translate(41.5 35) rotate(180)">
            <rect
              id="playerbar-1"
              className="player-bar"
              width="5"
              height="13"
              transform="translate(21 11)"
              fill="#35363a"
            />
          </g>
          <g id="Groupe_3888" data-name="Groupe 3888" transform="translate(47 35) rotate(180)">
            <rect
              id="playerbar-2"
              className="player-bar"
              data-name="playerbar-3"
              width="5"
              height="13"
              transform="translate(21 11)"
              fill="#35363a"
            />
          </g>
          <g id="Groupe_3889" data-name="Groupe 3889" transform="translate(52.5 35) rotate(180)">
            <rect
              id="playerbar-3"
              className="player-bar"
              data-name="playerbar-3"
              width="5"
              height="13"
              transform="translate(21 11)"
              fill="#35363a"
            />
          </g>
          <g id="Groupe_3890" data-name="Groupe 3890" transform="translate(36 35) rotate(180)">
            <rect
              id="playerbar-4"
              className="player-bar"
              data-name="playerbar-3"
              width="5"
              height="13"
              transform="translate(21 11)"
              fill="#35363a"
            />
          </g>
        </g>
      </svg>
      <svg
        id="btn-player-reduire"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.356934C0.89543 0.356934 0 1.25236 0 2.35693V20.4998C0 21.6044 0.89543 22.4998 2 22.4998H16.9762V29.1426L22.5971 22.4998H29C30.1046 22.4998 31 21.6044 31 20.4998V2.35693C31 1.25236 30.1046 0.356934 29 0.356934H2Z"
          fill="white"
        />
        <path d="M18.5459 8.18213L12.1819 14.5461" stroke="black" />
        <path d="M12.1821 8.18213L18.5461 14.5461" stroke="black" />
      </svg>
      <svg
        id="btn-player-restore"
        width="31"
        height="30"
        viewBox="0 0 31 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0.356934C0.89543 0.356934 0 1.25236 0 2.35693V20.4998C0 21.6044 0.89543 22.4998 2 22.4998H16.9762V29.1426L22.5971 22.4998H29C30.1046 22.4998 31 21.6044 31 20.4998V2.35693C31 1.25236 30.1046 0.356934 29 0.356934H2Z"
          fill="white"
        />
        <path d="M15.364 6.86377V15.8638" stroke="black" />
        <path d="M10.864 11.3638L19.864 11.3638" stroke="black" />
      </svg>
    </>
  );
};
