import React from "react";
import { Root, Routes } from "react-static";
import { Router } from "@reach/router";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import { Loading, TransitionRoot } from "@components/TransitionRoot";
import WrappedApp from "./WrappedApp";
import theme from "./theme";
import { anecdotes } from "./data/anecdotes";
import "./scss/master.scss";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Root>
        <TransitionRoot anecdote={anecdotes}>
          <React.Suspense fallback={<Loading />}>
            <Router>
              <Routes
                path="*"
                render={({ routePath, getComponentForPath }) => {
                  // The routePath is used to retrieve the component for that path
                  const element = getComponentForPath(routePath);
                  return <WrappedApp>{element}</WrappedApp>;
                }}
              />
            </Router>
          </React.Suspense>
        </TransitionRoot>
      </Root>
    </ThemeProvider>
  );
}

export default App;
