import React from "react";
import { PersistedDataContext } from "./PersistedDataContext";
import { settingsSlice } from "./settingsSlice";
import { placesSlice } from "./placesSlice";
import { PersistedDataPersisted, PersistedDataState } from "./index.interface";

function readFromStorage(n) {
  try {
    if (typeof localStorage !== "undefined") {
      const data = localStorage.getItem(n);
      return JSON.parse(data);
    }
  } catch (err) {
    console.error(err);
  }
  return {};
}

function writeToStorage(n, v) {
  try {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(n, JSON.stringify(v));
    }
  } catch (err) {
    console.error(err);
  }
}

function writeAllToStorage(np) {
  writeToStorage("settings", np.settings);
  writeToStorage("places", np.places);
}

export class PersistedData extends React.Component<{}, PersistedDataState> {
  constructor(props: {}) {
    super(props);
    const persisted: PersistedDataPersisted = {
      settings: {
        ...settingsSlice.initialState,
        ...readFromStorage("settings")
      },
      places: {
        ...placesSlice.initialState,
        ...readFromStorage("places")
      }
    };

    this.state = this.generateState(persisted);
  }

  generateState(persisted) {
    const functions = {
      dispatch: this.dispatch
    };
    const passed = {
      ...persisted,
      ...functions
    };
    return {
      persisted,
      passed
    };
  }

  dispatch = (fn) => {
    this.setState((state) => {
      const { persisted } = state;
      const result = fn(persisted);
      const np = {
        ...persisted,
        ...result
      };
      writeAllToStorage(np);
      return this.generateState(np);
    });
  };

  render() {
    return (
      <PersistedDataContext.Provider value={this.state.passed}>{this.props.children}</PersistedDataContext.Provider>
    );
  }
}
