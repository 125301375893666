import { Anecdote } from "@utils/data";

export const anecdotes: Anecdote[] = [
  {
    title: "Introduction",
    src: "narrations/v1/rz1-v2.mp3"
  },
  {
    title: "Deux maisons, deux destinations",
    src: "narrations/v1/rz2.mp3"
  },
  {
    title: "Un magicien",
    src: "narrations/v1/rz3.mp3"
  },
  {
    title: "Un amour du travail bien fait",
    src: "narrations/v1/rz4.mp3"
  },
  {
    title: "Anatomie de la Maison Sirois",
    src: "narrations/v1/rz5.mp3"
  },
  {
    title: "Regard sur le passé",
    src: "narrations/v1/rz6.mp3"
  },
  {
    title: "Un héritage impressionnant",
    src: "narrations/v1/rz7.mp3"
  },
  {
    title: "Le parcours de Victor Sirois – partie 1",
    src: "narrations/v1/rz8.mp3"
  },
  {
    title: "Le parcours de Victor Sirois – partie 2",
    src: "narrations/v1/rz9.mp3"
  },
  {
    title: "Le parcours de Victor Sirois – partie 3",
    src: "narrations/v1/rz10.mp3"
  },
  {
    title: "La relève d’Yvonne",
    src: "narrations/v1/rz11.mp3"
  },
  {
    title: "Mot de la fin",
    src: "narrations/v1/rz12.mp3"
  }
];
