import type { LocationParts } from "./data";

export const { WEBSITE_BASE_PATH } = process.env;
export const { WEBSITE_SITE_ROOT } = process.env;
export const { MEDIA_ROOT } = process.env;

/**
 * Emplacement de départ
 */
export const startingLocation: Required<LocationParts> = {
  lang: "fr",
  type: "place",
  id: null,
  sub: null
};
/**
 * Langues supportées
 */
export const siteLocales = ["fr"];

export const GTM_KEY = "G-E9QG6VQ4QB";

/**
 * Empècher l'accès direct aux éléments non débloqué
 */
export const preventDirectAccess = true;

export const { GENERATOR_ENV_NAME } = process.env;
export const { K_REVISION } = process.env;
export const TARGET_EMAIL = process.env.TARGET_EMAIL || "tommy.briere@pvp.ca";
// Activation de l'optimiseur de média lors de la génération
export const MEDIA_OPTIMISE = false;
// Activation du remplacement d'url
export const USE_OPTMISED_MEDIA = true;
export const RELOAD_AFTER_XWGL = 40;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDC7lxxHA4gRhXDZy0FJ8pmM83B6qIOYzE",
  authDomain: "maisonsirois.firebaseapp.com",
  projectId: "maisonsirois",
  storageBucket: "maisonsirois.appspot.com",
  messagingSenderId: "1079413446005",
  appId: "1:1079413446005:web:bacb88fb3ff6eac72aef3c"
};
