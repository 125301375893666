/**
 * @author Tommy Brière
 * @copyright (c) 2021 Cube Noir
 * @fileoverview Sauvegarde des endroits collectées
 * */

import { DataSlice, PersistedDataPersisted } from "./index.interface";
import { createAction } from "./utils";

export interface CollectedPlacesState {
  savedPositions: Record<string, PlacePosition>;
}

export interface PlacePosition {
  id: string;
  x: number;
  y: number;
}

const initialState: CollectedPlacesState = {
  savedPositions: {}
};

export const placesSlice: DataSlice = {
  name: "places",
  initialState,
  reducers: {
    clearPlace: createAction("places", (state: CollectedPlacesState) => {
      return initialState;
    }),
    setPlacePosition: createAction("places", (state: CollectedPlacesState, action: PlacePosition) => {
      return {
        ...state,
        savedPositions: {
          ...state.savedPositions,
          [action.id]: action
        }
      };
    })
  }
};

export const { clearPlace, setPlacePosition } = placesSlice.reducers;

// Other code such as selectors can use the imported `RootState` type
export const getPlacePosition = (state: PersistedDataPersisted, id: string) => state.places.savedPositions[id];
